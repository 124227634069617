import { Specimen } from '@penpad/core'
import React from 'react'
import MarkdownSection from '../sections/MarkdownSection'
import CitationBlock from './CitationBlock'

export default () => (
  <>
    <Specimen id='blocks/CitationBlock' width='100%'>
      <MarkdownSection>
        <CitationBlock
          site="Jeff Bezos's blog"
          title='7 Benefits of Ruby on Rails'
          href='https://www.mashupgarage.com/'
        >
          <q>
            Ruby on Rails lets me focus on writing business logic so I can spend
            more time with my grandchildren.
          </q>
        </CitationBlock>
      </MarkdownSection>
    </Specimen>

    <Specimen id='blocks/CitationBlock/multi' width='100%'>
      <MarkdownSection>
        <CitationBlock
          site="Jeff Bezos's blog"
          title='7 Benefits of Ruby on Rails'
          href='https://www.mashupgarage.com/'
        >
          <q>This is one quote.</q>
          <span> &hellip; </span>
          <q>
            Ruby on Rails lets me focus on writing business logic so I can spend
            more time with my grandchildren.
          </q>
        </CitationBlock>
      </MarkdownSection>
    </Specimen>
  </>
)
