import { Specimen } from '@penpad/core'
import React from 'react'
import LargeFooterSection from './LargeFooterSection'

export default () => (
  <>
    <Specimen
      id='sections/LargeFooterSection'
      width='100%'
      description='Site-wide footer used in all pages.'
    >
      <LargeFooterSection />
    </Specimen>
  </>
)
