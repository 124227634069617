import { Specimen } from '@penpad/core'
import React from 'react'
import MarkdownSection from '../sections/MarkdownSection'
import ArticleTitleBlock from './ArticleTitleBlock'
import { Paragraph } from './Placeholder'

export default () => (
  <>
    <Specimen
      id='blocks/ArticleTitleBlock'
      width='100%'
      description='For article titles'
    >
      <MarkdownSection>
        <ArticleTitleBlock title='Truth: Ruby on Rails is unbelievably fast.' />
        <Paragraph />
      </MarkdownSection>
    </Specimen>

    <Specimen
      id='blocks/ArticleTitleBlock/prelude'
      width='100%'
      description='For article titles'
    >
      <MarkdownSection>
        <ArticleTitleBlock
          prelude='Light-speed development'
          title='Phoenix and Elixir are incredibly performant.'
        />
        <Paragraph />
      </MarkdownSection>
    </Specimen>

    <Specimen
      id='blocks/ArticleTitleBlock/em'
      width='100%'
      description='For article titles'
    >
      <MarkdownSection>
        <ArticleTitleBlock prelude='Light-speed development'>
          <em>Phoenix and Elixir</em> are incredibly performant.
        </ArticleTitleBlock>
        <Paragraph />
      </MarkdownSection>
    </Specimen>
  </>
)
