import {
  CombineWebpackContext as Combine,
  Penpad,
  Specimen
} from '@penpad/core'

import { CodePanelPlugin } from '@penpad/plugin-code-panel'
import { ResponsiveViewPlugin } from '@penpad/plugin-responsive-view'

import React from 'react'

// @ts-ignore Webpack-specific behavior
const specimens = require.context('../', true, /\.specimens?\.(jsx?|tsx?)$/)

export default () => (
  <Penpad ui={{ title: 'Mashup Garage components' }}>
    <CodePanelPlugin />
    <ResponsiveViewPlugin />
    <Combine context={specimens} />
  </Penpad>
)
