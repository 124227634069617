import React from 'react'

const Paragraph = () => {
  const sentences = [
    'Eagerly, key advises their towel.',
    'Madly, postcard shows the tofu.',
    "One's salad's insect analyzed their vetted teddy bear, really recent.",
    "The shotglass said his head's scant chain.",
    "One's balance's farce fined his plastic's sarcastic depth, eagerly.",
    "Their farce's worm forged her period's ripe sight, really focal.",
    'But what abstracted?',
    'Yes, my dull science!',
    'A section defines her coffee of minds below laws.',
    'Confirm his number.',
    "Craft the balance's page.",
    "Our abstracted dog's not quite vision-like, which must be logical."
  ]

  sentences.sort(() => Math.random() - 0.5)
  return <p>{sentences.slice(0, 5).join(' ')}</p>
}

const H2 = () => {
  return <ShortText wrapper='h2' />
}

const H3 = () => {
  return <ShortText wrapper='h3' />
}

const ShortText = (props: { wrapper: 'span' | 'h2' | 'h3' }) => {
  const { wrapper: Wrapper } = props
  const headings = [
    'Ruby on Rails performance',
    'Elixir & Phoenix productivity',
    'Agile development',
    'Software engineering',
    'Team productivity'
  ]
  const idx = Math.round(Math.random() * (headings.length - 1))
  return <Wrapper>{headings[idx]}</Wrapper>
}

export { Paragraph, H2, H3 }
