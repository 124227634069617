import { Specimen } from '@penpad/core'
import React from 'react'
import MarkdownSection from './MarkdownSection'

export default () => (
  <>
    <Specimen
      id='sections/MarkdownSection'
      width='100%'
      description='Renders some Markdown markup.'
    >
      <MarkdownSection>
        <h1>Hello there</h1>

        <p>
          Eagerly, key advises their towel. Madly, postcard shows the tofu.
          One's salad's insect analyzed their vetted teddy bear, really recent.
          The shotglass said his head's scant chain. One's balance's farce fined
          his plastic's sarcastic depth, eagerly.
        </p>

        <p>
          Lift our bill. Science teaches their clock. But what abstracted?
          "Loathe her tofu," one's mouse's biblical farce refined. "Refine our
          funk," she snipped. "Lift the claw's accessory," he hoped. A cat mixes
          his bridge of shoes above oxides.
        </p>
      </MarkdownSection>
    </Specimen>

    <Specimen id='sections/MarkdownSection/h2' width='100%'>
      <MarkdownSection>
        <h2>Second-level heading</h2>

        <p>
          Right now, towel joins their pond. "Make our cat's cap!" her threat's
          solid number pointed. Eagerly, a smile understands her applesauce of
          solutions without belts. Egads, my scant ice! But what fined? The
          salad's flake preferred her new boss, really warm.
        </p>

        <h2>Other second-level heading</h2>

        <p>
          But who snipped? Oh no, my hot seed! But what cleaned? Madly, a grin
          defines his bridge of homes on lists. Cross their farce. Alas, my
          equal scion! Badly, crown shades our fiber. Oh no, my biblical crown!
          But who moded? Hide our pond.
        </p>
      </MarkdownSection>
    </Specimen>
  </>
)
