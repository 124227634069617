import { Specimen } from '@penpad/core'
import React from 'react'
import MarkdownSection from '../sections/MarkdownSection'
import DividerBlock from './DividerBlock'
import { H2, Paragraph } from './Placeholder'

export default () => (
  <>
    <Specimen id='blocks/DividerBlock' width='100%'>
      <MarkdownSection>
        <H2 />
        <Paragraph />
        <DividerBlock />
        <H2 />
        <Paragraph />
      </MarkdownSection>
    </Specimen>
  </>
)
